import React from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import PermissionsGate from "../commons/PermissionsGate";

const ActionEditUser = ({ row, editUserActivate, type, activate }) => {
  return (
    <PermissionsGate scopes={"resources.configuration.users.actions.edit"}>
      <div
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          color: "#404A56",
        }}
        data-testid={`EditUser_${row.user_id}`}
        onClick={() => editUserActivate(row, type, activate)}
      >
        {<DriveFileRenameOutlineIcon className="Cursor" />}
      </div>
    </PermissionsGate>
  );
};

export default ActionEditUser;
