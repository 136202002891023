import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import TableCell from "@mui/material/TableCell";
import BasicTable from "../commons/basicTable";
import { Button } from "@mui/material";
import Editusers from "./editusers";
import PermissionsGate from "../commons/PermissionsGate";
import { Alert } from "../commons/alert";
import { v4 as uuidv4 } from "uuid";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ActionDeleteUser from "./actionDeleteUser";
import ActionEditUser from "./actionEditUser";
import GenericBackdrop from "../commons/GenericBackdrop";

const Users = () => {
  const [t] = useTranslation("global");
  const [users, setusers] = useState([]);
  const columns = [
    "Users.Name",
    "Users.Email",
    "Users.Store",
    "Users.Role",
    "Users.Action",
  ];
  const condition = [
    "user_id",
    "store_id",
    "role_id",
    "names",
    "first_name",
    "second_name",
  ];
  const [edituser, setedituser] = useState(false);
  const [datauser, setdatauser] = useState({});
  const [type, settype] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const getUsers = () => {
    handleLoaderOpen();
    axiosPrivate
      .get(`users`)
      .then((response) => {
        setusers(response.data);
        handleLoaderClose();
      })
      .catch((_err) => {
        Alert(t("General.Message.ErrorService"), "error");
        handleLoaderClose();
      });
  };

  //deleted users
  const handleDeleteUser = (user_id) => {
    axiosPrivate
      .delete(`users`, {
        params: { user_id: user_id },
      })
      .then((response) => {
        if (response.status === 204 || response.data === "User deleted.") {
          getUsers();
          return Alert(t("Users.Delete.Deleted"), "success");
        }
      })
      .catch((err) => {
        let error = err;
        if (
          error?.response?.status === 404 ||
          error?.response?.data === "User already deleted."
        )
          return Alert(t("Users.Delete.AlreadyDeleted"), "warning");
        else if (
          error?.response?.status === 410 ||
          error?.response?.data === "User not exist."
        )
          return Alert(t("Users.Delete.NotExist"), "warning");
      });
  };

  const edituseractivate = (row, mytype, activate) => {
    settype(mytype);
    setedituser(activate);
    setdatauser(row);
  };

  const backDetails = (active) => {
    setedituser(active);
  };

  const createaction = (row) => {
    return (
      <Fragment key={uuidv4()}>
        <TableCell
          align="right"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActionEditUser
            row={row}
            editUserActivate={edituseractivate}
            activate={true}
            type={"edit"}
          />
          <ActionDeleteUser row={row} handleDeleteUser={handleDeleteUser} />
        </TableCell>
      </Fragment>
    );
  };

  const screenUsers = () => {
    if (edituser) {
      return (
        <div style={{ marginBottom: "20px" }}>
          <Editusers type={type} data={datauser} activate={backDetails} />{" "}
        </div>
      );
    } else {
      return (
        <Fragment>
          <GenericBackdrop open={loader} />
          <PermissionsGate
            scopes={"resources.configuration.users.actions.create"}
          >
            <div style={{ textAlign: "right", paddingBottom: "20px" }}>
              <Button
                style={{
                  background: "#FFC10E",
                  color: "#404A56",
                  marginLeft: "10px",
                  textTransform: "none",
                  minWidth: "133px",
                  height: "40px",
                  borderRadius: "8px",
                }}
                endIcon={<GroupAddIcon />}
                onClick={() => edituseractivate([], "create", true)}
                className="action-button-secondary"
              >
                {t("Buttons.New")}
              </Button>
            </div>
          </PermissionsGate>
          <PermissionsGate
            scopes={"resources.configuration.users.actions.view"}
          >
            {users ? (
              <BasicTable
                dataTable={users}
                dataColumns={columns}
                action={createaction}
                condition={condition}
                align="left"
              />
            ) : null}
          </PermissionsGate>
        </Fragment>
      );
    }
  };

  useEffect(() => {
    if (!edituser) {
      getUsers();
    }
  }, [edituser]);

  return <div className="App">{screenUsers()}</div>;
};

export default Users;
