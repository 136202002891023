import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FaceIcon from "@mui/icons-material/Face";
import Sesion from "../components/commons/sesion";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Users from "../components/users/users";
import Stores from "../components/stores/stores";
import Account from "../components/bank-account/bankAccount";
import PermissionsGate from "../components/commons/PermissionsGate";
import Integrations from "../components/integrations/integrations";
import { Alert } from "../components/commons/alert";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

export default function Configuration() {
  const [value, setValue] = React.useState(0);
  const arrTabs = [];
  const [t] = useTranslation("global");
  const [render, setRender] = useState("");
  const navigate = useNavigate();
  const useAuth = useAuthUser();
  const auth = useAuth();

  const handleIntegration = (index) => {
    if (index.toString() === "0") {
      setRender("Stores");
      setValue(1);
      if (localStorage.getItem("storesEmpty") === "true") {
        Alert(t("Integrations.Alert.AddStores"), "");
      } else {
        Alert(t("Integrations.Alert.Stores"), "");
      }
    } else if (index.toString() === "1") {
      navigate(`/PaymentIntegration`);
    } else {
      Alert("Opción disponible proximamente.", "");
    }
  };

  const fnGate = (tabName, foundRedirect) => {
    arrTabs.push(tabName);
    let scope =
      "resources.configuration." +
      tabName.charAt(0).toLowerCase() +
      tabName.slice(1) +
      (tabName.endsWith("s") ? "" : "s") +
      ".actions.view";
    return (
      <PermissionsGate scopes={scope} foundRedirect={foundRedirect}>
        <Tab
          icon={tabName === "Users" ? <FaceIcon /> : ""}
          iconPosition={"end"}
          onClick={() => handleTabs(tabName)}
          label={t("Configuration." + tabName)}
          style={{
            width: "172px",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: "400",
            color: "#4C5866",
          }}
        />
      </PermissionsGate>
    );
  };

  const handleTabs = (tabName) => {
    setRender(tabName);
    const index = arrTabs.indexOf(tabName);
    setValue(index);
  };

  const renderiza = () => {
    switch (render) {
      case "Users": {
        return <Users />;
      }
      case "Stores": {
        return <Stores />;
      }
      case "BankAccount": {
        return <Account />;
      }
      case "Integrations": {
        return <Integrations onClick={handleIntegration} />;
      }
      default:
        return auth.roles.resources.configuration.users.actions.view ===
          true ? (
          <Users />
        ) : (
          <Stores />
        );
    }
  };

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Configuration"} />
      <PermissionsGate
        scopes={"resources.configuration.stores.actions.view"}
        foundRedirect={true}
      >
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {fnGate("Users")}
          {fnGate("Stores")}
          {fnGate("BankAccount")}
          {fnGate("Integrations")}
        </Tabs>
        <Divider
          sx={{
            background: "#DFE3E7",
          }}
        />
        <div style={{ paddingTop: "50px" }}>{renderiza()}</div>
      </PermissionsGate>
    </div>
  );
}
