import { React, useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../themes/Theme";
import Transfer from "./transfers";
import BalanceDetail from "./balanceDetail";
import { Chart } from "./chart";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { formatYMD, monthAdd } from "../../utils/validateExpression";
import { DataTableFormat, ChatFormat } from "../commons/setDataTable";
import GenericBackdrop from "../commons/GenericBackdrop";
import { useAuthUser } from "react-auth-kit";

export default function Insights() {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const useAuth = useAuthUser();
  const auth = useAuth();

  const [loader, setLoader] = useState(false);
  const [balance, setbalance] = useState([]);

  const [chartDataInfo, setChartDataInfo] = useState({
    legend: [],
    data: { labels: [], datasets: [] },
  });
  const [transfersData, setTransfersData] = useState({
    data: [],
    columns: [],
    dataBackUp: [],
    columnsSearch: [],
    datesFilter: [],
  });

  useEffect(() => {
    setLoader(true);

    const getInsights = async () => {
      try {
        const today = new Date();
        const response = await axiosPrivate.get(`insight`, {
          params: {
            date_init: formatYMD(monthAdd(today, -1)),
            date_end: formatYMD(today),
          },
        });

        setbalance(response.data["balance"]);
        setChartDataInfo(ChatFormat(response.data["chart"], t));
        let permissionGranted = "resources.insights.actions.details"
          .split(".")
          .reduce((obj, i) => obj[i], auth?.roles);
        if (!permissionGranted) {
          response.data["table"].columns.pop("Adjustment");
        }
        setTransfersData(DataTableFormat(response.data["table"], t));

        setLoader(false);
      } catch (err) {
        console.error(err);
        setLoader(false);
        navigate("/SignIn", { state: { from: location }, replace: true });
      }
    };

    getInsights();
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <ThemeProvider theme={Theme}>
        {t("Insights.Message.Information")}
        <BalanceDetail balance={balance} />
        <Chart chartDataInfo={chartDataInfo} />
        <Transfer transfersData={transfersData} />
      </ThemeProvider>
    </Fragment>
  );
}
