import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const getNavigate = (auth, location, allowedRoles) => {
  if (allowedRoles?.includes(auth?.roleName)) return <Outlet />;

  if (auth?.authToken)
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;

  return <Navigate to="/SignIn" state={{ from: location }} replace />;
};

const RequireRole = ({ allowedRoles }) => {
  const useAuth = useAuthUser();
  const auth = useAuth();
  const location = useLocation();
  return getNavigate(auth, location, allowedRoles);
};

export default RequireRole;
