import { Navigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const PermissionsGate = ({ children, foundRedirect = null, scopes = [] }) => {
  const useAuth = useAuthUser();
  const auth = useAuth();

  let permissionGranted =
    auth?.roles &&
    scopes
      .toString()
      .split(".")
      .reduce((obj, i) => obj?.[i], auth?.roles);

  if (!permissionGranted && !foundRedirect) return <></>;

  if (!permissionGranted && foundRedirect) return <Navigate to="/Insight" />;

  return <>{children}</>;
};

export default PermissionsGate;
