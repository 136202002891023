import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuthUser } from "react-auth-kit";
import { getClientId } from "../utils/jwt";
import Swal from "sweetalert2";
import account from "../../assets/generic/FinishImage.svg";
import CardActions from "@mui/material/CardActions";
import { currencyFormatter } from "../../utils/validateExpression";

export default function AddTransferVerify({
  data,
  showVerifyTransfer,
  showCreateTransfer,
  idAccount,
}) {
  const [t] = useTranslation("global");
  const [request, setrequest] = useState(false);
  const useAuth = useAuthUser();
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const postTransfer = async () => {
    const controller = new AbortController();
    const body = {
      amount: parseFloat(data.Amount).toFixed(2),
      client_id: getClientId(auth),
      account_id: idAccount,
    };
    try {
      await axiosPrivate.post("withdrawals", body, {
        signal: controller.signal,
      });
      setrequest(true);
    } catch (err) {
      showCreateTransfer(false);
      console.error(err);
      Swal.fire({
        title: "Error!",
        text: t("Transfer.Error"),
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      {request ? (
        <Card style={{ padding: "5%", borderRadius: "16px" }}>
          <CardContent>
            <Typography
              color={"Gray"}
              variant="h6"
              gutterBottom
              component="div"
              sx={{
                textAlign: "center",
                color: "#404A56",
                fontSize: "24px",
              }}
            >
              {t("Transfer.MessageRequest")}
            </Typography>
            <Typography
              gutterBottom
              component="div"
              sx={{
                textAlign: "center",
                marginTop: "25px",
                color: "#404A56",
                fontSize: "16px",
              }}
            >
              {t("Transfer.MessageReview")}
            </Typography>
            <Typography
              gutterBottom
              component="div"
              sx={{
                textAlign: "center",
                color: "#404A56",
                fontSize: "16px",
              }}
            >
              {t("Transfer.MessageReview2")}
            </Typography>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <img src={account} alt={"AddAccount"} />
            </div>
            <CardActions style={{ justifyContent: "center" }}>
              <Button
                style={{
                  background: "#FFC10E",
                  color: "#404A56",
                  marginLeft: "10px",
                  textTransform: "none",
                  minWidth: "180px",
                  height: "48px",
                  marginTop: "25px",
                }}
                onClick={() => window.location.replace("")}
                className="action-button"
              >
                {t("Transfer.Finish")}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      ) : (
        <>
          <Card>
            <div style={{ width: "390px" }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  marginLeft: "24px",
                  paddingTop: "32px",
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "#404A56",
                }}
              >
                {t("Transfer.TransferConfirm")}
              </Typography>
              <Typography
                color={"Gray"}
                gutterBottom
                component="div"
                sx={{
                  marginLeft: "24px",
                  fontSize: "18px",
                  paddingTop: "30px",
                }}
              >
                {t("Transfer.TransferMsgWithdraw")}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                component="div"
                sx={{
                  marginLeft: "20px",
                  fontSize: "32px",
                  paddingTop: "5px",
                  fontWeight: "bold",
                  color: "#404A56",
                }}
              >
                {currencyFormatter(data.Amount, "es-HN", "HNL")}
              </Typography>
              <CardContent sx={{ maxWidth: "600px" }}>
                <Divider sx={{ marginBottom: "24px", borderBottomWidth: 2 }} />
                {Object.entries(data).map((item, index, array) =>
                  array.length - 1 !== index ? (
                    <Grid
                      container
                      spacing={2}
                      style={{ paddingTop: "3px" }}
                      key={`g0-${index}`}
                    >
                      <Grid item xs={6} key={`g0-1-${index}`}>
                        <Typography
                          sx={{ m: 0.5, fontSize: "16px", color: "#708195" }}
                          align="left"
                          key={`t0-${index}`}
                        >
                          {t("Transfer." + item[0])}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} key={`g1-${index}`}>
                        <Typography
                          sx={{
                            m: 0.5,
                            fontSize: "14px",
                            color: "#404A56",
                            fontWeight: "600",
                          }}
                          align="right"
                          key={`t1-${index}`}
                        >
                          {item[1]}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <div key={`add-transfer-div-empty`}></div>
                  )
                )}
              </CardContent>
            </div>
          </Card>
          <div
            style={{
              float: "right",
              marginBlockEnd: "30px",
              marginTop: "30px",
            }}
          >
            <Button
              style={{
                background: "white",
                color: "#404A56",
                marginLeft: "10px",
                textTransform: "none",
                minWidth: "131px",
                height: "48px",
              }}
              onClick={() => showCreateTransfer(false)}
              className="action-button-outlined"
            >
              {t("Buttons.Cancel")}
            </Button>
            <Button
              variant="outlined"
              style={{
                background: "white",
                color: "#404A56",
                textTransform: "none",
                minWidth: "131px",
                borderColor: "#FFC10E",
                height: "48px",
              }}
              onClick={() => showVerifyTransfer(false)}
            >
              {t("Buttons.Edit")}
            </Button>

            <Button
              style={{
                background: "#FFC10E",
                color: "#404A56",
                marginLeft: "10px",
                textTransform: "none",
                minWidth: "131px",
                height: "48px",
              }}
              onClick={() => postTransfer()}
              className="action-button"
            >
              {t("Buttons.Continue")}
            </Button>
          </div>
        </>
      )}
    </>
  );
}
