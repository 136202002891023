import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import useLogout from "../../hooks/useLogout";
import { useAuthUser } from "react-auth-kit";

const Sesion = ({ screen }) => {
  const [t] = useTranslation("global");
  const useAuth = useAuthUser();
  const auth = useAuth();
  const logout = useLogout();

  const getInitials = (name) => {
    let names = name?.split(" ");
    let nameReturn = names?.[0].substring(0, 1) + names?.[1].substring(0, 1);
    return nameReturn;
  };

  const signOut = async () => {
    logout();
  };

  return (
    <Grid container direction="row" style={{ paddingBottom: "2%" }}>
      <Grid item xs={12} sm={9}>
        <Typography
          color="#343C46"
          fontSize="42px"
          fontFamily="Nunito"
          fontWeight="600"
          variant="h4"
          gutterBottom
          component="div"
        >
          {t(`Menu.${screen}`)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={9} sm={10}>
            <Grid container direction="column">
              <Grid item xs={2} sm={2}>
                <Typography
                  color="#4C5866"
                  gutterBottom
                  fontSize="16px"
                  fontFamily="Nunito Sans"
                  fontWeight="bold"
                  component="div"
                  textAlign="right"
                >
                  {auth.name}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Typography
                  color="#708195"
                  gutterBottom
                  fontSize="14px"
                  fontFamily="Nunito Sans"
                  fontWeight="regular"
                  component="div"
                  textAlign="right"
                >
                  <label
                    style={{ cursor: "pointer" }}
                    role="lbl-logout"
                    onClick={signOut}
                  >
                    {t("Menu.Logout")}
                  </label>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} style={{ textAlign: "end" }}>
            <Avatar
              alt="Remy Sharp"
              sx={{ bgcolor: "#DADADA", width: 48, height: 48 }}
            >
              {getInitials(auth?.name)}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Sesion;
