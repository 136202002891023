import { currencyFormatter } from "../../utils/validateExpression";

export function DataTableFormat(data, t) {
  let dateInt;
  let dateEnd;
  const setDates = (date) => {
    if (dateInt == null && dateEnd == null) {
      dateInt = new Date(date);
      dateEnd = new Date(date);
    } else {
      let newDate = new Date(date);
      dateInt = dateInt > newDate ? newDate : dateInt;
      dateEnd = newDate > dateEnd ? newDate : dateEnd;
    }
  };

  let result = {};
  if (data !== "") {
    const trans = "Insights.Transactions.Table.";
    let col = [];

    for (let value of Object.values(data.columns)) {
      col.push(trans.concat(value));
    }
    for (let values of Object.values(data.results)) {
      if (values.amount) {
        values.amount = currencyFormatter(
          values.amount,
          t("Locales.local"),
          t("Locales.currency")
        );
      }
      setDates(values.date);
    }

    result["datesFilter"] = {
      dateInit: dateInt,
      dateEnd: dateEnd,
    };
    result["columns"] = col;
    result["data"] = data.results;
    result["dataBackUp"] = data.results;
    result["columnsSearch"] = data.search;
  }

  return result;
}

const barColor = {
  Done: "#FABE00",
  Reimbursed: "#FFD445",
  Error: "#DFE3E7",
  Reverted: "#FFD445",
};
const barStyle = {
  fill: true,
  borderWidth: 0,
  barPercentage: 0.6,
  borderSkipped: false,
  categoryPercentage: 0.8,
  borderRadius: Number.MAX_VALUE,
};

function sumArray(array) {
  const initialValue = 0;
  return array.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
  );
}

export function ChatFormat(response, t) {
  const { periods, datasets } = response;

  const result = {};
  result["legend"] = [
    ...datasets.map((dataset) => {
      return {
        label: t(`Insights.Transactions.Keys.${dataset.key}`),
        amount: sumArray(dataset.data),
        color: barColor[dataset.key],
      };
    }),
  ];

  /*En caso de que el periodo sea menor de 12 meses, se aumenta el tamaño del periodo para renderizar las graficas con el tamaño correcto*/
  while (periods.length < 12) {
    periods.push("");
  }

  result["data"] = {
    labels: periods,
    datasets: [
      ...datasets.map((dataSet) => {
        return {
          label: t(`Insights.Transactions.Keys.${dataSet.key}`),
          data: dataSet.data,
          backgroundColor: barColor[dataSet.key],
          ...barStyle,
        };
      }),
    ],
  };

  return result;
}
