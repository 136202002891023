import axios from "../api/axios";
import { useAuthUser } from "react-auth-kit";

const useRefreshToken = () => {
  const useAuth = useAuthUser();
  const auth = useAuth();
  let RefreshToken = localStorage.getItem("RefreshToken");

  return async () => {
    try {
      axios.defaults.headers.common["mts-token"] = RefreshToken;
      const response = await axios.get("/token/refresh");
      let authChange = auth;
      authChange.authToken = response.data.authToken;
      auth({ authChange });
      return response.data.authToken.AccessToken;
    } catch (err) {
      return false;
    }
  };
};

export default useRefreshToken;
