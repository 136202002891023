import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Checkbox,
  Link,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import OnBoardingLayout from "../components/on-boarding-layout";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { SnackBar } from "./../components/commons/alert";
import GenericBackdrop from "./../components/commons/GenericBackdrop";
import { contractHN, anexoA, anexoB } from "../translations/contracts";
import { getCountry } from "../components/utils/getCountry";
import signContract from "../assets/generic/signContract.svg";
import { useAuthUser } from "react-auth-kit";
import useLogout from "../hooks/useLogout";

function ContractScreen() {
  const useAuth = useAuthUser();
  const auth = useAuth();
  const logout = useLogout();
  const [checked, setChecked] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderToSign, setLoaderToSign] = useState(false);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const userId = location.state.userId;
  const email = location.state.email;
  const [dataKym, setDataKym] = useState(null);
  const [commission, setCommission] = useState(null);
  const contractKYM = contractHN;
  const [country, setCountry] = useState("");
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const date = new Date();
  let typeContract;
  let nameContract;
  let lastNameContract;

  const HtmlText = (code) => {
    return <div dangerouslySetInnerHTML={{ __html: code }} />;
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleNext = () => {
    setLoaderToSign(true);
    console.log(userId);
    axiosPrivate
      .patch(`signContract`, {
        user_id: auth?.userId,
      })
      .then((response) => {
        if (response.data.message === "Sended email") {
          navigate("/messageScreen", {
            screen: "MessageScreen",
            state: {
              type: response.data.data_kym,
            },
          });
        }
        setLoaderToSign(false);
      })
      .catch((_) => {
        setLoaderToSign(false);
        SnackBar(t("General.Message.ErrorService"), "error");
      });
    logout();
  };

  const handleReturn = () => {
    navigate("/SignIn");
    logout();
  };

  const styles = {
    contentView: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
      boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      borderRadius: "16px",
      backgroundColor: "white",
      margin: "0px 72px 0px 72px",
    },

    contractContainer: {
      overflowY: "scroll",
      height: "80%",
      "&::-webkit-scrollbar": {
        width: 11,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C4C4C4",
        borderRadius: 10,
      },
    },

    containerView: {
      height: "55%",
      position: "absolute",
      zIndex: "auto",
      scroll: "auto",
    },
  };

  useEffect(() => {
    setLoader(true);
    const controller = new AbortController();
    const countryValue = getCountry();
    setCountry(countryValue);

    async function getCommission() {
      try {
        axiosPrivate
          .get(`commissions/${email}`, {
            signal: controller.signal,
          })
          .then((response) => {
            setCommission(response.data.commission.percent);
            setLoader(false);
          });
      } catch (err) {
        setLoader(false);
        navigate("/SignIn");
      }
    }

    async function getDataKym() {
      try {
        const headers = {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_KYM_BACK_API_KEY,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_KYM_BACK_DOMAIN}/user?email=${email}`,
          { headers, signal: controller.signal }
        );
        setDataKym(response.data);
        getCommission();
      } catch (err) {
        console.error(err);
        setLoader(false);
        navigate("/SignIn");
      }
    }

    getDataKym();

    return () => {
      controller.abort();
    };
  }, []);

  if (dataKym != null) {
    if (
      dataKym?.Negocio["Tipo-empresa"]?.type_of_company == "persona_natural"
    ) {
      typeContract = "Contract.ContractNP";
      nameContract = dataKym.Negocio["general-data"].name;
      lastNameContract = dataKym.Negocio["general-data"].lastname;
    } else if (
      dataKym?.Negocio["Tipo-empresa"]?.type_of_company == "persona_juridica"
    ) {
      typeContract = "Contract.ContractJP";
      nameContract = dataKym.Negocio["general-data"]["legal-name"];
      lastNameContract = dataKym.Negocio["general-data"]["legal-lastname"];
    } else {
      typeContract = "Contract.Contract";
      nameContract = dataKym.Negocio["general-data"].name;
      lastNameContract = dataKym.Negocio["general-data"].lastname;
    }
  }

  return dataKym ? (
    <Fragment>
      <GenericBackdrop open={loaderToSign} />
      <Box sx={styles.containerView} mt={12}>
        <Container sx={{ height: "100%", paddingTop: "50px" }} maxWidth={false}>
          <Box sx={styles.contentView} p={5}>
            <Box>
              <Stack direction="row" width="100%">
                <Stack>
                  <Typography variant="title" component="div" gutterBottom>
                    {t("Contract.Title")}
                  </Typography>
                  <Typography variant="subTitle" component="div" gutterBottom>
                    {t("Contract.SubTitle")}
                  </Typography>
                </Stack>
                <Stack
                  sx={{ marginLeft: "40px", marginTop: "-25px" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img width="114px" src={signContract} />
                </Stack>
              </Stack>
            </Box>
            <Box mt={4} pr={2} sx={styles.contractContainer}>
              <Typography
                align="justify"
                variant="body"
                component="div"
                gutterBottom
              >
                {HtmlText(
                  t(typeContract, {
                    name: nameContract,
                    lastname: lastNameContract,
                    identityDocument:
                      dataKym.Negocio["general-data"]["identity-document"],
                    idDocument: dataKym.Negocio["general-data"]["id-document"],
                    street: dataKym.Negocio["comertial-information"].street,
                    city: dataKym.Negocio["comertial-information"].city,
                    state: dataKym.Negocio["comertial-information"].state,
                    country: country,
                    businessName:
                      dataKym.Negocio["comertial-information"]["business-name"],
                    email: dataKym.email,
                    rtn: dataKym.Negocio["comertial-information"].rtn,
                    contract: contractKYM,
                    currentDate:
                      date.getDate() +
                      " de " +
                      months[date.getMonth()] +
                      " de " +
                      date.getFullYear(),
                  })
                )}
                {HtmlText(anexoA.replace("[CONTRACT_TYPE]", commission))}
                {HtmlText(anexoB)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: "180px" }} mt={5}>
            <Grid container direction="row" pl={5} pr={5}>
              <Grid item xs={12} md={5} lg={8}>
                <Grid container direction="row" alignItems="center">
                  <Box>
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{
                        "aria-label": "controlled",
                        "data-testid": "check-id",
                      }}
                    />
                    <Typography variant="checkbox">
                      {t("Contract.CheckboxText")}
                      <Link href="/privacy" color="inherit" target="_blank">
                        {t("Contract.LinkText")}
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7} lg={4}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={6} md={3}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      onClick={handleReturn}
                      className="action-button-outlined"
                    >
                      {t("Contract.NextButton")}
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!checked}
                      onClick={handleNext}
                      className="action-button"
                      data-testid="btn-continuar"
                    >
                      {t("Contract.PrevButton")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Fragment>
  ) : (
    <GenericBackdrop open={loader} />
  );
}

export default function Contract() {
  return (
    <OnBoardingLayout screen={<ContractScreen />} withoutContainer={true} />
  );
}
