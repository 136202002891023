import React from "react";
import Button from "@mui/material/Button";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../themes/Theme";
import { Alert, Confirm } from "../components/commons/alert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { getClientId } from "../components/utils/jwt";
import { useAuthUser } from "react-auth-kit";

export const SendEmail = ({ fileData, fileName }) => {
  const [t] = useTranslation("global");
  const useAuth = useAuthUser();
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();

  function postTransfer(dataFile, name) {
    let param = {
      ...dataFile,
      name,
      receiver: auth.email,
      client_id: getClientId(auth) ?? "",
    };
    axiosPrivate
      .post("transaction/reports/emails", {
        body: param,
        jobNumber: param.client_id,
      })
      .then((response) => {
        if (response.status === 200) {
          return Alert(t("Transfer.SuccessEmailText"), "success");
        } else {
          return Confirm("Error!", t("Transfer.Error"), "success");
        }
      })
      .catch(() => {
        return Alert(t("Transfer.Error"), "error");
      });
  }

  return (
    <ThemeProvider theme={Theme}>
      <Button
        variant="outlined"
        startIcon={<EmailOutlinedIcon />}
        onClick={() => postTransfer(fileData, fileName)}
        className="action-button-outlined-secondary"
      >
        {t("Buttons.Email")}
      </Button>
    </ThemeProvider>
  );
};
